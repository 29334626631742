/**
 * Fix #21 - https://debug.not.live/marquetis/ppg.chromatic/issues/21#note_98838
 **/
@media (min-width: 1024px) {
	#header.fixed-position .user-links a::before {
		color: #ccc;
	}
}

/**
 * Fix #49 - https://debug.not.live/marquetis/ppg.chromatic/issues/49#note_98912
 **/
@media (max-width: 1023px) {
	.hero-carousel {
		/* margin-bottom: 0; */
	}

	.hero-carousel + .slider-content.outer-content {
		padding-top: 15px;
		padding-bottom: 15px;
		background: #e6e6e6;
	}
}

/**
 * Fix #42 - https://debug.not.live/marquetis/ppg.chromatic/issues/42#note_97185
 **/
.profile-form .jcf-select {
	height: 36px;
}

/**
 * Fix #63 - https://debug.not.live/marquetis/ppg.chromatic/issues/63
 **/
.cta-disconnect {
	text-align: right;
}

.btn-link {
	background: none;
	border: 0;
	color: #000;
	padding-left: 0;
	padding-right: 0;
}
.btn-link:hover {
	color: #868686;
}

.icon-power-off:before {
  content: "\f011";
}

/**
 * Fix #46 - https://debug.not.live/marquetis/ppg.chromatic/issues/46
 **/
.contrast-result {
	visibility: hidden;
	opacity: 0;
	-webkit-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
}

.contrast-result.is-active {
	opacity: 1;
	visibility: visible;
}

/**
 * Fix #62 - https://debug.not.live/marquetis/ppg.chromatic/issues/62#note_99017
 **/
.content-section.with-image.half-image {
	-webkit-box-align: stretch;
	    -ms-flex-align: stretch;
	        align-items: stretch;
}

.content-section.with-image .img-holder {
	margin-right: 0;
}

@media (min-width: 480px) {
	.content-section.with-image .img-holder {
		margin-right: 15px;
	}
}

#footer {
	background: #e7e6e6;
	color: #000;
}

#footer .copyright a {
	color: #000;
}

#footer .container > span {
	font-weight: 700;
}

/**
 * Fix #80 - https://debug.not.live/marquetis/ppg.chromatic/issues/
 **/

div.slide-img-block{
width: auto;
margin-left:15px;
}

#footer .brands-list {
	-webkit-box-align: end;
	    -ms-flex-align: end;
	        align-items: flex-end;
	margin-bottom: 38px;
}

#footer .brands-list li a {
	display: block;
}

.footer-logo {
	text-align: center;
	font-size: 12px;
}

.footer-logo.left {
	right: auto !important;
	left: 15px;
}

.footer-logo.left span {
	display: block;
	margin-top: 4px;
}

#footer .footer-logo {
	bottom: 65px;
}

@media (min-width: 1024px) {
	#footer .footer-logo {
		bottom: 71px;
	}

	#footer .footer-logo.left {
		left: 45px;
	}
}

#footer {
	padding-bottom: 140px;
}


@media (min-width: 1024px) {
	#footer {
		padding-bottom: 70px;
	}
}

.content-section > .img-holder img {
	max-width: 100%;
}

@media (min-width: 1024px) {
	.accordion .slide-img-block {
		max-width: 50%;
	}
}
